@import '@scss/service';

.inputAutocompleteWidget {
  position: relative;
  display: inline-block;

  .input {
    &Field {
      width: 100%;
    }

    &Select {
      position: absolute;
      width: 100%;
      z-index: 99999;
      font-size: inherit;
      border: 1px solid rgba(72,73,81,0.2);
      background: white;
      overflow-y: auto;
      max-height: 264px;
      box-shadow: 0 4px 4px 0 rgba(0,0,0,0.25);

      :global .select-widget__control {
        display: none;
      }

      &::-webkit-scrollbar {
        width: 4px;
        height: 47px;
      }

      &::-webkit-scrollbar-track {
        background: rgba(255,255,255,0);
      }

      &::-webkit-scrollbar-thumb {
        background: rgba(72,73,81,0.2);
        border-radius: 4px;
        border: 4px solid transparent;
        box-shadow: inset -10px 0 0 0 #f0f0f0;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: $form-border;
      }
    }

    &Item {
      width: 100%;
      padding: 8px;
      cursor: pointer;
      border: none;
      border-bottom: 1px solid rgba(72,73,81,0.2);
      background-color: #fff;
      color: #000;
      text-align: left;

      &:hover {
        background-color:rgba(72,73,81,0.2);
      }
    }

    &Selected {
      background-color: $form-border;
      color: #fff;
    }
  }
}
