@import '@scss/service';

.title {
  font-size: 1.88em;
  text-align: center;
  margin-bottom: 0.5em;
}

.slider {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5em;

  &:last-of-type {
    margin-bottom: 3em;
  }

  &Container {
    padding: 1.3em 1em 1em;
    background-color: $white;

    :global .input-widget {
      width: 100%;
      height: 32px;

      input {
        padding: 0;
        border-radius: 5px;
      }
    }
  }

  & &Panel {
    padding: 1em;
  }

  .amount {
    text-align: center;
    font-size: 1.5em;
    font-weight: 300;

    &Output {
      color: $gray;
    }
  }
}

.loan {
  &Button {
    font-size: 2em;
    color: $white;
    max-width: 270px;
    margin: auto;
  }
}
