@import '@scss/service';

.phoneNumber {
  margin-top: 2em;

  @include respond(md) {
    margin-top: 1em;
  }
}

.input {
  &Widget {
    width: 48%;
    margin-top: 1.5em;

    @include respond(xs) {
      width: 100%;
    }
  }
}

.inputMask {
  &Widget {
    width: 100%;
    margin-top: 1.5em;

    @include respond(xs) {
      width: 100%;
    }
  }
}
