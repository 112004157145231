.text {
  &Holder {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    text-align: center;
    min-height: 180px;
  }
}
