@import '@scss/service';

.file {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  height: 77px;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 77px;
  border-radius: 10px;

  /* &:hover {
    .attachments-form__clear-button {
      opacity: 1;

      .ui-button {
        display: block;
      }
    }
  } */

  img {
    width: 100%;
  }

  &Icon {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;

    width: 100%;
    height: 100%;
  }
}

.addButtonWrap {
  display: flex;
  align-items: center;
  justify-content: center;
}

.addButton {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed $gray;
  box-sizing: border-box;
  height: 45px;
  width: 45px;
  cursor: pointer;
  margin: auto;

  &.disabled {
    cursor: auto;
    opacity: 0.5;
    pointer-events: none;
  }

  .input {
    display: none;
  }
}

.commandButton {
  @extend %button;
  display: block;
  width: 100%;
  //max-width: 420px;

  &.disabled {
    background: $white;
    border: 1px solid $blue;
    color: $blue;
    opacity: 1;
    cursor: auto;

    &:hover:not(:disabled) {
      background: $white;
      color: $blue;
    }
  }

  .input {
    display: none;
  }
}

.button {
  font-size: px2em(14px);
  width: px2em(170px);
  height: px2em(170px);
  padding: px2em(14px);
  text-align: center;
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-flow: column;
  color: lighten($black, 50%);
  min-width: 25%;
}

.button {
  @extend %shadow;
  background-color: $white;
  border-radius: 6px;
  border: none;
  user-select: none;
  cursor: pointer;

  @include respond(sm) {
    margin-top: px2em(20px);
    width: 100%;
    flex-flow: row;
    height: auto;
  }
}

/* .input {
  display: none;
} */
