@import '@scss/service';
@import '../Client.module.scss';

.job {
  @extend .clientForm;

  .workPeriod {
    margin-top: px2em(15px);
    width: 48%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    @include respond(xs) {
      width: 100%;
    }

    &Title {
      width: 100%;
      margin-bottom: px2em(15px);
    }

    .input {
      margin: 0;
      width: 48%;
    }
  }
}
