@import '@scss/service';

.rateFeedback {
  padding: 14px 20px;
  border-radius: 8px;
  background: #F4F5F9;
  border: 1px solid #C6CAD8;
  margin-bottom: 40px;
  &.wide {
    border: none;
    padding: 0;

    .starsBlock {
      padding: 14px 20px;
      border: 1px solid #C6CAD8;
      border-radius: 8px;
      background: $white;
    }
  }

  .title {
    font-size: 16px;
    font-weight: 600;
    line-height: 27.2px;
    text-align: center;
    margin-bottom: 8px;
  }

  .subtitle {
    font-size: 14px;
    font-weight: 400;
    line-height: 19.6px;
    text-align: center;
  }

  .legend {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    text-align: left;
  }

  .stars {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 24px;

    .number {
      font-size: 12px;
      font-weight: 400;
      line-height: 14px;
      text-align: center;
    }
  }

  .hands {
    font-size: 96px;
    font-weight: 400;
    line-height: 134.4px;
    text-align: center;
  }

  .reviewForm {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
  }

  .submit {
    border: 0;
    padding: 16px 40px 16px 40px;
    border-radius: 8px;
    justify-content: space-between;
    background: #2A70C8;
    font-size: 17px;
    font-weight: 600;
    line-height: 20.4px;
    text-align: center;
    color: white;
    width: 100%;

    &:disabled {
      background: #C6CAD8;
    }
  }

  textarea {
    resize: none;
    width: 100%;
    padding: 8px 16px;
    border-radius: 8px;
    border: 1px solid #C6CAD8;
    margin: 0 0 20px;

    &.warning {
      border-color: red;
    }
  }

  button {
    background: none;
    border: none;
    cursor: pointer;
    margin-top: 10px;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    svg {
      margin: 8px 0 16px;
    }
  }

}
