@import '@scss/service';
@import '../Client.module.scss';

.serviceMessage {
  margin-top: 0.5em;
}

.tabs {
  padding: 0 2.19em;
  width: 100%;
}

.documents {
  @extend .clientForm;
  max-width: 420px;
  flex: 1;

  .attachments {
    margin-bottom: 40px;
    .buttons {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
    }
  }

  .fullrow {
    flex-basis: 100%;
  }

  .title {
    color: $re-blue;
    margin: 46px 0 8px;
    font-size: 25px;
    font-weight: 600;
    line-height: 30px;
    text-align: center;
  }

  .subtitle {
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
    text-align: center;
  }
}

.accounts {
  margin: 2em 0 1em;
}

.buttonWrap {
  margin-top: 6.93em;
  margin-bottom: 8.8em;

  @include respond($xs) {
    margin-top: 2.43em;
    margin-bottom: 2.9em;
  }
}
