@import '@scss/service';

.vendors {
  padding: 3em 5em;
  background: linear-gradient(77.74deg, $gray-light 0%, $gray-misty 100%);
  border-radius: 0.5em;
  margin: 40px 0;
  max-width: 75em;
  display: flex;
  gap: 1em;
  align-items: center;

  @include respond(md) {
    flex-direction: column;
    padding: 3em 1em;
  }

  @include respond(359px) {
    padding: 3em 0em;
  }
}

.title {
  font-size: 2.25em;
  color: $blue;
  text-align: center;

  br {
    display: none;

    @include respond(xs) {
      display: inline;
    }
  }
}

.content {
  display: flex;
  gap: 1em;
  justify-content: center;
}

.img {
  width: 154px;
}
