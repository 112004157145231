.container {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  position: fixed;
  z-index: 999;

  background-color: rgb(255 255 255 / 50%);
  //background: radial-gradient(circle, white 1%, rgba(255, 255, 255, 0.5) 25%);

  background: radial-gradient(circle, #ffffff00 1%, rgb(0, 0, 0, 0.4) 15%);
}
