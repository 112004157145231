@import '@scss/service';

.abtitle {
  color: red;
  font-weight: 700;
}

.accounts {
  font-size: 1.1em;
  @include respond(md) {
    font-size: 0.9em;
  }

  &Form {
    width: 100%;

    &Title {
      font-size: 11px;
      margin-bottom: 4px;
      position: relative;
      font-weight: 700;
      text-transform: uppercase;
      letter-spacing: 1px;
    }

    .label {
      font-size: 11px;
      margin-bottom: 4px;
      position: relative;
      font-weight: 700;
      text-transform: uppercase;
      letter-spacing: 1px;
    }

    .holder {
      display: flex;
      flex-direction: column;
      // width: 328px;
      align-items: center;
      // justify-content: space-between;
    }

    .select {
      width: 100%;

      &:global.select-widget {
        .select-widget__control {
          min-height: auto;

          &--menu-is-open {
            .select-widget {
              &__indicator {
                transform: none;
                width: 8px;
                height: 24px;
                background: url('/theme/icons/triangle-blue.svg') center no-repeat;
              }
            }
          }
        }

        .select-widget__indicator {
          padding: 0 16px;
          &:before {
            width: 24px;
            height: 24px;
            background: url('/theme/icons/iconamoon_edit.svg') no-repeat;
          }
        }
      }
    }

    .error {
      border-color: $red;
      .newAccSection {
        border-color: $red;
      }

      &Text {
        color: $red;
      }
    }
  }

  &Note {
    margin-top: 1em;
    font-weight: bold;
  }
}

.title {
  font-size: 1.1em;
  font-weight: normal;
  text-align: center;
}

.add {
  &Title {
    @extend .title;
    font-size: 1em;
    font-weight: bold;
    margin-top: 1em;
  }

  &SubTitle {
    @extend .title;
    font-size: 1em;
  }
}

.libraPayData {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  margin-top: 1em;
}

.customAccounts {
  margin-top: 16px;

  &.opened {
    border-radius: 10px;
    border: 1px solid #4caf50;
    background: rgba(76, 175, 80, 0.05);
    padding: 0 16px 16px 16px;
  }
}

.newAccBlock {
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  row-gap: 16px;
}

.newAccSection {
    padding: 20px 16px;
    border-radius: 8px;
    border: 1px solid #C6CAD8;
    background: #F4F5F9;
    width: 100%;

  .addAccount {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    padding: 16px 32px;
    border-radius: 8px;
    background: $blue;
    color: #fff;
    cursor: pointer;
    border: none;
    font-size: 16px;
    font-weight: 700;
    line-height: 19.2px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    width: 100%;
  }
}

.aslink {
  display: flex;
  color: $green;
  font-weight: 700;
  border: none;
  border-bottom: 1px dashed;
  border-radius: 0;
  width: fit-content;
  margin: 0 auto;
  padding: 0;
  align-items: center;
  cursor: pointer;
  &:hover:not(:disabled) {
    font-weight: 400;
  }
}

.button {
  width: 100%;
}

.buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;

  @include respond(sm) {
    flex-direction: column;
  }
}

.input {
  @extend %input;

  border-radius: 10px;
  border: 1px solid $gray-light;
  padding: 0.5em;
  font-size: 1.1em;
  &Widget {
    width: 100%;
  }
}

.error {
  border-color: $red;
}

.framed,
.shaded {
  background-color: $white;
  box-sizing: border-box;
  width: 100%;

  :global .select-widget__control {
    &::after {
      display: none;
    }
  }

  :global .select-widget__value-container {
    padding: 0.79em 1em;
    white-space: normal;
  }
}

.framed {
  border: 1px solid $gray-light;
  padding: 1em;
  box-shadow: $box-shadow;

  /* :global .select-widget__control {
    border: 1px solid $gray-light;

    &:hover {
      border: 1px solid $gray-light;
    }
  } */
}

.shaded {
  :global .select-widget__control {
    border: none;

    &:hover {
      border: none;
    }
  }

  :global .select-widget {
    box-shadow: $box-shadow;
  }
}

.nonBankingDayNotify {
  display: block;
  margin-top: 1em;
}
