@import '@scss/service/';
$mob: $sm;
$tab: $xxs, $xl;

.section {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
}

.content {
  display: flex;
  width: 100%;
  max-width: $re-xl;

  @media (max-width: 1280px) {
      margin: 16px auto 48px;
      padding: 0 16px;
  }

  @media (min-width: 1281px) {
    padding: 0 16px;
    margin: 40px auto 80px;
  }
}

.arrow {
  &Prev {
    display: flex;
    align-items: center;
    @include respond($mob) {
      display: none;
    }
  }
  &Next {
    display: flex;
    align-items: center;
    transform: rotate(180deg);
    @include respond($mob) {
      display: none;
    }
  }
}

.contentWrap {
  width: 100%;
  min-height: 560px;
  position: relative;
  display: flex;
  margin: 0 58px;
  column-gap: 54px;
  @include respond($tab) {
    flex-direction: column;
    margin: 0;
    row-gap: 40px;
  }
}

.bannerWrap {
  width: 100%;
  @include respond($tab) {
    position: inherit;
  }
}

.banner {
  position: relative;
  width: 56%;
  max-width: $re-xl - 116px;
  min-height: 560px;
  display: flex;
  justify-content: space-between;

  @include respond($tab) {
    padding: 0;
    max-width: $md;
    margin: auto;
    width: 100%;
  }
  @include respond($mob) {
    max-width: 360px;
    min-height: 405px;
    width: 100%;
  }
}

.main {
  &Content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  &TextWrap {
    background-image: $rainbowGradient;
    border-radius: 32px;
    margin-top: 48px;
    padding: 40px;
    width: 100%;
    max-width: 530px;
    height: 100%;
    max-height: 432px;
    @include respond($mob) {
      margin-top: 0;
      padding: 36px 16px;
      max-width: 248px;
      max-height: 212px;
    }
  }

  &Text {
    font-size: 36px;
    color: $white;
    margin: 0;
    font-weight: 700;
    line-height: 130%;
    @include respond($mob) {
      font-size: 24px;
    }

    * {
      line-height: 130%;
    }
  }

  &Image {
    flex-grow: 1;
    position: absolute;
    right: 0;
    display: flex;
    justify-content: center;
    width: 60%;
    max-width: 440px;
    @include respond($tab) {
      transform: none;
    }
    @include respond($mob) {
      width: 100%;
      max-width: 281px;
      bottom: 44px;
      justify-content: end;
    }

    .img {
      position: relative;
      left: 16px;
      @include respond($mob) {
        left: unset;
        right: 16px;
        max-width: 139px;
      }
    }
  }
}

.description {
  font-size: 14px;
  color: $re-gray-dark;
  margin: 16px 0 0 16px;
  width: 50%;
  @include respond($mob) {
    font-size: 11px;
    margin-top: 8px;
    width: 40%;
  }

  br {
    @include respond($mob) {
      display: none;
    }
  }
}

.options {
  position: absolute;
  bottom: -68px;
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 16px;
  @include respond($mob) {
    bottom: -44px;
  }

  .option {
    display: flex;
    &-0 {
      justify-content: right;
      @include respond($mob) {
        justify-content: center;
      }
    }
    &-1 {
      margin-top: -8px;
      justify-content: left;
      @include respond($mob) {
        margin-top: 3px;
      }
    }
    &-2 {
      margin-top: 32px;
      margin-left: 16px;
      justify-content: center;
      @include respond($mob) {
        margin-top: 3px;
        margin-left: 0;
        justify-content: right;
      }
    }

    .iconWrap {
      display: flex;
      align-items: center;
    }

    .icon {
      flex-shrink: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 32px 0px 0px 32px;
      padding: 16px 8px;
      @include respond($mob) {
        width: 32px;
        height: 32px;
        padding: 8px 8px;
      }

      &-blue {
        background: $re-blue;
      }
      &-red {
        background: $re-red;
      }
      &-yellow {
        background: $re-yellow;
      }
    }

    .text {
      display: flex;
      height: 72px;
      padding: 24px 16px;
      align-items: center;
      text-align: left;
      border-radius: 0px 8px 8px 0px;
      background: $re-gray-light;
      @include respond($mob) {
        height: 48px;
      }
    }
  }
}

.productSelector {
  flex-shrink: 0;
  z-index: 1;
  min-height: 462px;

  @media screen and (max-width: 1200px) {
    min-height: 362px;
  }
}
