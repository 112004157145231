@import '@scss/service';

.error {
  border-color: $red;
}

.agree_terms {
  margin: px2em(20px) auto;
  display: flex;
  align-items: flex-start;
}

.agree_text {
  text-align: justify;

  .link {
    color: $blue;
  }
}

.message {
  font-size: 0.75em;
  color: $red;
  text-align: left;
  margin-left: 1.67em;
  position: absolute;
  top: 0;
}

.codeForm {
  position: relative;
  align-items: center;
  display: flex;
  flex-direction: row;
}

.codeLabel {
  z-index: 2;
  color: $gray;
  top: 50%;
  left: 1.25em;
  position: absolute;
  white-space: nowrap;
  white-space: nowrap;
  font-size: 0.92em;

  @include respond(sm) {
    white-space: normal;
  }
}

.otpHolder {
  width: 100%;
}

.input {
  @extend %input;

  width: 100%;
  letter-spacing: 1em;
  border: 1px solid $gray;
  border-radius: 10px;
  font-size: 1em;
  padding: 1.3em 1.25em 0.75em 1.25em;
  line-height: 1.38em;

  @include respond(xs) {
    letter-spacing: 0.5em;
    text-indent: 0.5em;
  }

  &::placeholder {
    text-decoration: none;
    opacity: 0;
  }

  & + hr {
    display: none;
  }
}
.clearOtp {
  background: transparent url('/theme/icons/clear_code.png') no-repeat;
  border: none;
  box-shadow: none;
  height: 25px;
  width: 33px;
  flex-shrink: 0;

  &:hover:not(:disabled) {
    cursor: pointer;
    animation: zoom 0.5s linear 0s infinite alternate;
    background: transparent url('/theme/icons/clear_code.png') no-repeat;
  }
}

.resend {
  text-align: left;
  margin-top: 0.5em;

  .button {
    font-size: 1em;
    border: none;
    background: transparent;
    cursor: pointer;
    color: $blue;

    &:hover {
      background: transparent;
      color: $blue;
      text-decoration: underline;
    }
  }
}

.testOtp {
  @extend %shadow;
  pointer-events: none;
  position: fixed;
  left: 0;
  top: 0;
  text-align: center;
  width: 100%;
  padding: 20px;
  background-color: $yellow;
  opacity: 0.8;
  font-size: 3em;
  font-weight: bold;
  letter-spacing: px2em(4px);
  z-index: 999;
  color: $red;
  animation: fade-out 0.2s linear 5s forwards;
}
