@import '@scss/service';

.select,
.inputMask {
  &Widget {
    width: 100%;
    margin-top: 1.5em;

    @include respond(xs) {
      width: 100%;
    }
  }
}
