@import '@scss/service';

.info {
  box-sizing: border-box;
  width: 100%;

  .title {
    font-size: 1.1em;
    font-weight: bold;
    padding-bottom: 0.7em;
    color: $gray-dark;

    &Collapsed {
      cursor: pointer;
    }
  }

  .link {
    color: $blue;
    text-decoration: underline;
  }

  .separator {
    display: none;
    border-bottom: 1px solid $gray-light;
    margin: 0.75em auto;
  }

  .accent {
    font-weight: bold;
  }

  .datatable {
    &Collapsed {
      display: none;
    }

    table {
      width: 100%;
      border-collapse: collapse;
      table-layout: fixed;
    }

    tbody {
      font-size: 1em;

      tr {
        td {
          &:first-child {
            text-align: left;
            padding: 0.5em 0.5em 0.5em 1em;

            &::after {
              content: ':';
            }
          }

          &:last-child {
            text-align: right;
            padding: 0.5em 1em 0.5em 0.5em;
          }
        }
      }
    }
  }

  &.default {
    .datatable {
      background-color: $gray-misty;
      border-radius: 10px;
      border-top: 6px solid transparent;
      border-bottom: 6px solid transparent;

      &Open {
        border-radius: 0 0 10px 10px;
      }
    }

    .title {
      &Collapsed {
        display: flex;
        justify-content: space-between;
        background-color: $gray-misty;
        padding: 1em;
        border-radius: 10px;

        &:after {
          display: block;
          content: '';
          width: 16px;
          height: 16px;
          background: url('/theme/icons/icons_16x16.svg') -48px -64px no-repeat;
        }
      }

      &Open {
        border-radius: 10px 10px 0 0;

        &:after {
          transform: rotate(180deg);
        }
      }
    }

    .afterContent {
      padding: 0 1em 1em;
    }
  }

  &.shaded {
    @extend %shadow;
    padding: 1em;

    @include respond(960) {
      max-width: 23.25em;
      padding: 1.34em 1em;
    }

    .title {
      text-align: center;
    }

    .datatable {
      padding: 2.68em 1em 1em;
      font-weight: bold;

      thead {
        text-align: center;

        td {
          padding-bottom: 1.25em;
        }
      }

      tbody {
        font-size: 0.9em;

        tr {
          border-bottom: 1px solid $gray-light;

          td {
            padding: 0.8em 0.75em;

            &:first-child {
              color: $gray;
              text-align: right;
            }
          }

          &:last-child {
            border-bottom: none;
          }
        }
      }
    }

    .link {
      color: $red;
    }
  }

  &.centered {
    .title {
      text-align: center;
    }

    .datatable {
      padding: 2.68em 1em 1em;
      font-weight: bold;

      thead {
        text-align: center;

        td {
          padding-bottom: 1.25em;
        }
      }

      tbody {
        font-size: 0.9em;

        tr {
          border-bottom: 1px solid $gray-light;

          td {
            padding: 0.8em 0.75em;

            &:first-child {
              color: $gray;
              text-align: right;
            }

            &:last-child {
              text-align: left;
            }
          }

          &:last-child {
            border-bottom: none;
          }
        }
      }
    }
  }
}
