.processingTimer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 12px 0 27px;

  .timerTitle {
    font-weight: normal;
    margin-right: 10px; // Optional: add space between title and circle
  }

  .circularTimer {
    position: relative;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;

    .timerSvg {
      transform: rotate(90deg) scaleX(-1); // Start from the top and flip horizontally
    }

    .timerCircle {
      transition: stroke-dashoffset 1s linear;
    }

    .timerText {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 16px;
      font-weight: 600;
      text-align: center;
    }
  }
}
