@import '@scss/service';
@import './../Client.module.scss';

.serviceMessage {
  margin-top: 0.5em;
}

.tabs {
  padding: 0 2.19em;
  width: 100%;
}

.unsafe_tabs {
  padding: 0 1em;
  width: 100%;
}

.obligatory {
  @extend .clientForm;
  max-width: $sm;
  min-width: 328px;

  &Buttons,
  &PromoFields {
    max-width: $xs - 60;
    margin: auto;
    margin-top: 2em;
  }
}

.link {
  color: $gray-light;
  font-size: 0.875em;
  display: flex;
  justify-content: center;
  margin: 0.71em auto 0 auto;
  background-color: inherit;
  border: none;

  &:hover:not(:disabled) {
    background: inherit;
    color: $gray;
  }
}

.row {
  @extend .clientForm;
  max-width: $sm;

  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
}

.email {
  margin-top: 2em;

  @include respond(md) {
    margin-top: 1em;
  }
}

.container {
  padding: 0;
  gap: 48px;
  max-width: 420px;
  width: 100%;

  @media screen and (max-width: 360px) {

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
    gap: 48px;

    position: absolute;
    width: 328px;
    height: 952px;
    left: 16px;
    top: 132px;

    border-radius: 8px;

  }

  .float {
    &SubmitButtonPanel {
      position: fixed;
      display: flex;
      justify-content: center;
      z-index: 10;
      bottom: 0;
      left: 0;
      width: 100%;
      transition: all 0.2s ease;
      padding: 16px;
      border-top: #bfc6dc 1px solid;
      background-color: #f4f5f9;

      .accept {
        max-width: 328px;
        width: 100%;
        text-transform: none;
        font-weight: 600;
        font-size: 17px;
        font-style: normal;
        line-height: 20px;
        text-align: center;
        margin: 0 auto;

        &:disabled {
          color: #ffffff;
          background-color: #bfc6dc;
          border-color: #bfc6dc;
        }
      }
    }
  }


}
