@import '@scss/service';

.policy {
  width: 100%;

  @include respond(xs) {
    width: 95%;
  }

  &Title {
    margin-top: 3.3em;
    margin-bottom: 1.5em;

    font-family: $font-family;
    font-style: normal;
    font-weight: 700;
    font-size: 11px;
    line-height: 140%;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #484951;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;

    @include respond(xs) {
      width: 328px;
    }
  }

  &Block {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 16px;
    gap: 32px;
    border: 1px solid #BFC6DC;
    border-radius: 8px;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    font-family: $font-family;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    @include respond(xs) {
      width: 328px;
    }

    &__content {
      font-size: 14px;
    }

    &__error {
      border-color: $red;
    }
  }
}

.conditions {
  margin: 1em auto;
}

.agreement {
  display: flex;
  margin-bottom: 0.5em;

  &:last-child {
    margin-bottom: 0;
  }

  label:before {
    border: 1px solid gray;
  }

}

.switch {
  &Block {
    display: flex;
    column-gap: 8px;
    align-items: center;
    width: 100%;

    :global {
      .react-switch-handle {
        background: #BFC6DC !important;
      }

      .react-switch-bg {
        background: #ffffff !important;
        border: 2px solid #BFC6DC;
      }
    }
  }

  &Checked {
    :global {
      .react-switch-handle {
        background: #2A70C8 !important;
      }

      .react-switch-bg {
        background: #ffffff !important;
        border: 2px solid #2A70C8;
      }
    }

  &Invalide {
    :global {
      .react-switch-handle {
        background: #FF0000 !important;
      }

      .react-switch-bg {
        background: #ffffff !important;
        border: 2px solid #FF0000;
      }
    }
  }
  }
}
