@import '@scss/service';

.title {
  &Wrap {
    display: flex;
    justify-content: space-between;
    font-size: 20px;
    color: $re-blue;
  }

  &Value {
    font-weight: 700;
  }
}

.slider {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;

  &:last-of-type {
    margin-bottom: 18px;
  }

  &Container {
    background-color: $white;

    [class~='input-widget'] {
      width: 100%;
      height: 32px;

      input {
        padding: 0;
        border-radius: 5px;
      }
    }
  }

  & &Panel {
    padding: 14px 42px 8px;

    :global {
      .rc-slider {
        display: flex;
        justify-content: space-between;
        &::before,
        &::after {
          color: $re-yellow;
          font-size: 18px;
          font-weight: 700;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          background: $re-blue;
          width: 24px;
          height: 24px;
          margin-top: -10px;
        }
        &::before {
          content: '-';
          margin-left: -42px;
        }
        &::after {
          content: '+';
          margin-right: -42px;
        }
      }

      .rc-slider-rail {
        background-color: $re-gray-light;
        display: flex;
        justify-content: space-between;
        border-radius: 0;
        &::before {
          content: '';
          margin-left: -18px;
          background-color: $re-blue;
          height: 4px;
          width: 18px;
        }
        &::after {
          content: '';
          margin-right: -18px;
          background-color: $re-gray-light;
          height: 4px;
          width: 18px;
        }
      }

      .rc-slider-track {
        background: $blue-gradient;
        border-radius: 0;
      }

      .rc-slider-dot {
        border-color: $re-gray;
        background: $white;

        &-active {
          border-color: $re-blue;
          background: $re-blue;
        }
      }

      .rc-slider-handle {
        margin-top: -3px;
        background: url('/theme/icons/handler-icon.svg') no-repeat center;
        width: 36px;
        height: 20px;
        border-radius: 0;
        opacity: 1;
        &:before,
        &:after {
          display: none;
        }

        &-dragging {
          border: none;
          box-shadow: none;
        }
        &:active,
        &:focus-visible {
          box-shadow: none;
        }
      }

      .rc-slider-mark {
        top: 16px;

        &-text {
          font-size: 12px;
          color: $re-gray;

          &:first-child {
            transform: translateX(-24px) !important;
          }
          &:last-child {
            transform: translateX(-30px) !important;
          }
        }
      }
    }
  }

  .amount {
    text-align: center;
    font-size: 1.5em;
    font-weight: 300;

    &Output {
      color: $gray;
    }
  }
}
