@import '@scss/service';

.section {
  background-color: $gray-misty;
  margin-bottom: 0.5em;
  padding: 0.5em;
  display: flex;

  @include respond(sm) {
    flex-direction: column;
    align-items: center;
  }
}

.icon {
  background: url('/theme/icons/icons_124x124.svg') no-repeat;
  width: 124px;
  height: 124px;
  margin-right: 1em;
  flex-shrink: 0;

  @include respond(sm) {
    margin: 0;
  }

  &0 {
    background-position: -248px -124px;
  }

  &1 {
    background-position: 0 -248px;
  }

  &2 {
    background-position: -128px -248px;
  }

  &3 {
    background-position: -372px -372px;
  }
}

.button {
  border: none;
  cursor: pointer;
}

.title {
  font-size: 1.34em;
}

.container {
  width: 100%;
}

.content {
  overflow: hidden;
}

.collapsed {
  max-height: 0;
}

.text,
.list {
  font-size: 1.31em;
  font-weight: 300;
  color: $gray;
}

.list {
  padding-left: 1em;

  .list {
    font-size: inherit;
  }
}
