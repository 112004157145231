@import '@scss/service';

.rateCapTransfer {
  margin-top: 48px;
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.title {
  font-weight: 700;
  text-align: center;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 16px;
  background-color: $gray-misty;
  border-radius: 10px;
  row-gap: 16px;

  .link {
    color: $blue;
    text-decoration: underline;
  }

  .button {
    // @extend %button;
    width: 100%;
    text-transform: uppercase;
    line-height: 22px;
    padding: 16px;

    @include respond(sm) {
      max-width: none;
      padding: 16px 8px;
    }
  }
}

.otpBlock {
  text-align: center;
}

.otp {
  width: 100%;
  position: relative;
  padding-top: 16px;
  @include respond(xs) {
    padding-top: 32px;
  }
}
