@import '@scss/service';

.select {
  font-size: inherit;
  border-radius: 10px;
  border: 1px solid $form-border;

  &:global.select-widget--has-error {
    border-color: $red;

    .selectLabel {
      color: $red;
    }
  }

  :global {
    .select-widget__value-container {
      top: 4%;
    }

    .select-wiget_indicatior {
      display: none;
    }
  }

  &Control {
    height: 100%;
  }

  &Label {
    color: #707070;
    position: absolute;
    width: 100%;
    top: 15%;
    font-size: 0.75em;
    padding-left: 1.5em;
    // transition: all 0.2s;
    // pointer-events: none;
  }

  /* Option container */
  .select-widget__option {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: blue;

    /* Checkmark pseudo-element */
    &--is-selected {
      background: red;
    }
    &--is-selected::after {
      content: '';
      background-image: url('./check.svg');
      background-size: contain;
      background-repeat: no-repeat;
      width: 16px;
      height: 16px;
      position: absolute;
      right: 10px; // Adjust spacing from the right edge
    }
  }

  .customButtonContainer {
    padding: 20px 0;
    border-top: 1px solid $gray;
    .customButton {
      cursor: pointer;
      padding: 8px 40px;
      border: none;
      background: $white;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 16px;
    }
  }

  .filter {
    &__menu {
      margin: 0.125rem auto;
    }

    &__option {
      background-color: red;

      &--is-focused {
        background-color: blue;
      }
    }

    &__group {
      padding: 0;
    }

    &__menu-portal {
      border: 1px solid darkblue;
    }
  }
}
