@import '@scss/service';

.iframe {
  background-color: $white;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
  border: none;
}
