@import '@scss/service';

.checkbox {
  font-size: 1em;

  label {
    position: relative;

    &:before {
      border: 1px solid $gray;
      background-color: $white;
      margin-right: 8px;
      transition: border-color 0.3s, background-color 0.3s;
    }
  }

  &.checked label {
    &:before {
      border-color: $blue;
      background-color: $blue;
    }
  }
}

.error {
  label {
    &:before {
      border-color: $red;
    }
  }
}

