@import '@scss/service';

.buttonWrap {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  flex-shrink: 0;
  border-top: 1px solid $re-gray;
  border-bottom: 1px solid $re-gray;
  padding: 16px 0;
  margin: 40px 0;
}

.status {
  font-size: 0.85em;

  &ButtonWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    cursor: pointer;
    background-color: $white;
    position: relative;
    font-size: 18px;
    font-weight: 600;
    line-height: 30.6px;
    text-align: center;

    span {
      margin-left: 16px;
    }
  }

  &Active {
    animation-name: reloader;
    animation-duration: 1.1s;
    animation-timing-function: linear;
  }
}

@keyframes reloader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
