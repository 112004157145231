@import '@scss/service';

.popupOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 1000;
}

.popupContainer {
  position: relative;
  width: 100%;
  max-width: 500px;
  background: #F4F5F9;
  border-radius: 40px 40px 0 0;
  padding: 20px 20px 0;
  box-shadow: 0 -5px 10px rgba(0, 0, 0, 0.1);
  animation: slideUp 0.3s ease;

  .titleIcon {
    margin: auto;
    text-align: center;
  }
  .title {
    font-size: 25px;
    font-weight: 600;
    line-height: 30px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin: 12px auto 40px;
    max-width: 75%;

    svg {
      fill: $re-blue;
    }
  }

  .subtitle {
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin: -20px 32px 40px;
  }

  .popupContent {
    margin-bottom: 20px;

    .buttonsSection {
      button {
        cursor: pointer;
        border: 1px;
        padding: 16px 40px 16px 40px;
        border-radius: 8px;
        background: $blue;
        font-size: 17px;
        font-weight: 600;
        line-height: 20.4px;
        text-align: center;
        color: white;
        width: 100%;
        margin-bottom: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;

        &.card,
        &.iban {
          &:disabled {
            background: $re-gray;
            border: $re-gray;
            color: $white;
            svg {
              path {
                fill: $white;
              }
            }
          }
        }
        &.iban {
          background: $white;
          border: 1px solid $blue;
          color: $blue;
        }
      }
    }

    .cardSection {
      //display: none;
    }

    .ibanSection {
      [class*="error"] {
        color: red;
        border-color: red;
      }
      input {
        font-size: 16px;
        font-weight: 400;
        line-height: 18px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: $blue;
        margin-bottom: 16px;
        &:focus,
        &:active {
          border: 1px solid $blue;
          color: $blue;
        }
      }
      button {
        cursor: pointer;
        border: 1px;
        padding: 16px 40px 16px 40px;
        border-radius: 8px;
        background: $blue;
        font-size: 17px;
        font-weight: 600;
        line-height: 20.4px;
        text-align: center;
        color: white;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        margin-top: 16px;
        &:disabled {
          background: $re-gray;
          border: $re-gray;
          color: $white;
          svg {
            path {
              fill: $white;
            }
          }
        }
      }
    }

    .closeButton {
      display: flex;
      margin: 40px auto 20px;
      cursor: pointer;
      color: $blue;
      border: none;
      padding: 0;
      font-size: 17px;
      font-weight: 600;
      line-height: 20.4px;
      text-align: center;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
    }
  }

}

.nonBankingTime {
  background: #F6F7FB;
  border: 1px solid #F6F7FB;
  padding: 16px;
  border-radius: 8px;
  margin-top: 8px;
}

@keyframes slideUp {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}