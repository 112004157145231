@import '@scss/service';

.clientForm {
  font-size: 1em;
  max-width: 420px; //$sm;
  width: 100%;

  @include respond(md) {
    font-size: 0.88em;
  }

  .title {
    text-align: center;
    margin-top: 2.4em;
    font-weight: 600;
    font-size: 0.88em;

    svg {
      vertical-align: middle;
    }
  }

  .fields {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @include respond(xs) {
      flex-direction: column;
    }
  }

  .input {
    &Widget {
      width: 48%;
      margin-top: 1.5em;

      @include respond(xs) {
        width: 100%;
      }
    }
  }

  .unsafe_input {
    &Widget {
      width: 100%;
      margin-top: 1.5em;

      @include respond(xs) {
        width: 100%;
      }
    }
  }

  .select,
  .inputMask {
    &Widget {
      width: 48%;
      margin-top: 1.5em;

      @include respond(xs) {
        width: 100%;
      }
    }
  }

  .unsafe_select,
  .unsafe_inputMask {
    &Widget {
      width: 100%;
      margin-top: 1.5em;

      @include respond(xs) {
        width: 100%;
      }
    }
  }

  .datepicker {
    width: 48%;
    margin-top: 1.5em;

    @include respond(xs) {
      width: 100%;
    }
  }

  .button {
    &Widget {
      // margin: 3.5em auto 0;
      margin: auto;
      width: 100%;
      //max-width: 420px;
      display: block;
    }
  }
}


.unsafe_title {
  margin-top: 2.4em;
  width: 328px;
  height: 15px;
  font-family: $font-family;
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 140%;
  letter-spacing: 1px;
  text-transform: uppercase;

  color: #484951;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;


  svg {
    vertical-align: middle;
  }
}
