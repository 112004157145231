@import '@scss/service';

.actions {
  flex-shrink: 0;

  .amount {
    &Info {
      background-color: $white;
      box-shadow: $box-shadow;
      padding: 2.68em;
      border-radius: 10px;
    }

    &Text {
      color: $gray-dark;
      font-size: 1.1em;
      font-weight: bold;
      text-align: center;
    }
  }

  .inputWidget {
    margin-top: 1.87em;

    .input {
      border: 2px solid $gray-light;
      font-size: 1.35em;
      text-align: center;

      &.error,
      &.error:disabled {
        border-color: $red;
      }
    }

    :global .currency {
      font-size: 1.2em;
      font-weight: bold;
    }
  }

  .button {
    font-size: 1.13em;
    padding: 0.86em;
    border-radius: 10px;
    margin-top: 0.68em;
    overflow: hidden;
    width: 100%;

    &:disabled {
      opacity: 0.7;
      cursor: auto;
    }

    &Text {
      color: $gray;
      text-transform: uppercase;
    }

    &Amount {
      color: $blue;
    }
  }

  &.default {
    .button {
      background-color: $white;
      border: 1px solid $gray-light;
      box-shadow: $box-shadow;

      &:hover {
        box-shadow: none;
        background-color: $white;
      }

      &:disabled {
        box-shadow: none;
      }
    }
  }

  &.color {
    margin-bottom: 3em;

    .inputWidget {
      margin-top: 0.75em;
      margin-bottom: 3em;
    }

    .amount {
      &Info {
        background-color: $white;
        box-shadow: none;
        padding: 0;
        border-radius: 0;
      }
    }

    .button {
      &:disabled {
        background-color: $blue;
      }

      &Amount,
      &Text {
        color: $white;
        font-weight: bold;
      }
    }
  }
}
