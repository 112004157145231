@import '@scss/service';
$mob: $sm;
$tab: $xxs, $xl;

.section {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
}

.content {
  display: flex;
  width: 100%;
  max-width: $re-xl;

  @media (max-width: 1280px) {
      margin: 16px auto 48px;
      padding: 0 16px;
  }

  @media (min-width: 1281px) {
    padding: 0 16px;
    margin: 40px auto 80px;
  }

  .mainContent {
    height: 432px;
    position: relative;
    @include respond($mob) {
      height: 268px;
    }
    &:before {
      content: '';
      display: block;
      position: absolute;
      z-index: -1;
      background: linear-gradient(306.02deg, #F4F5F9 19.09%, #C6C8D3 97.33%);
      width: 530px;
      height: 432px;
      border-radius: 32px;
      @include respond($mob) {
        height: 230px;
        width: 260px;
      }
    }

    .mainTextWrap {
      display: flex;
      flex-direction: row;
      padding: 12px 40px;

      @include respond($mob) {
        padding: 6px 17px;
      }
      .numberGradient {
        width: min-content;
        font-weight: 800;
        line-height: 100%;
        vertical-align: middle;
        font-size: 193px;
        background: linear-gradient(to left, #FEC601, #CE1127, #2A70C8);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        @include respond($mob) {
          font-size: 118px;
        }
      }
      .mainText {
        color: #484951;
        padding: 28px 10px;
        white-space: break-spaces;
        @include respond($mob) {
          font-size: 24px;
          padding: 12px 2px;
        }
      }
    }

    .bubbleText {
      position: absolute;
      top: 22px;
      right: 137px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 174px;
      height: 174px;
      border-radius: 50%;
      background: #CE1127;
      transform: rotate(20deg);
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      //letter-spacing: 3;
      text-align: center;
      color: #fff;

      @include respond($mob) {
        z-index: 1;
        width: 125px;
        height: 125px;
        font-size: 12px;
        top: 0;
        right: 0;
      }
    }

    .img {
      position: absolute;
      bottom: -4px;
      right: -1px;
      @include respond($mob) {
        padding: 0 14px;
        bottom: 0;
        right: -10px;
      }
    }
  }
}

.arrow {
  &Prev {
    display: flex;
    align-items: center;
    @include respond($mob) {
      display: none;
    }
  }
  &Next {
    display: flex;
    align-items: center;
    transform: rotate(180deg);
    @include respond($mob) {
      display: none;
    }
  }
}

.contentWrap {
  width: 100%;
  position: relative;
  display: flex;
  margin: 0 58px;
  column-gap: 47px;
  @include respond($tab) {
    flex-direction: column;
    margin: 0;
    row-gap: 40px;
  }
  @include respond($mob) {
    gap: 0;
  }
}

.bannerWrap {
  width: 100%;
  @include respond($tab) {
    position: inherit;
  }
}

.banner {
  position: relative;
  width: 700px;
  max-width: $re-xl - 116px;
  min-height: 473px;
  display: flex;
  flex-direction: column;

  @include respond($tab) {
    padding: 0;
    max-width: $md;
    margin: auto;
    width: 100%;
  }
  @include respond($mob) {
    max-width: 360px;
    min-height: 405px;
    width: 100%;
  }
}

.main {
  &Content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  &TextWrap {
    @include respond($mob) {
      margin-top: 0;
      padding: 36px 16px;
      max-width: 248px;
      max-height: 212px;
    }
  }

  &Text {
    font-size: 36px;
    color: $white;
    margin: 0;
    font-weight: 700;
    line-height: 130%;
    @include respond($mob) {
      font-size: 24px;
    }

    * {
      line-height: 130%;
    }
  }

  &Image {
    flex-grow: 1;
    position: absolute;
    right: 0;
    display: flex;
    justify-content: center;
    width: 60%;
    max-width: 440px;
    @include respond($tab) {
      transform: none;
    }
    @include respond($mob) {
      width: 100%;
      max-width: 281px;
      bottom: 44px;
      justify-content: end;
    }

    .img {
      position: relative;
      left: 16px;
      @include respond($mob) {
        left: unset;
        right: 16px;
        max-width: 139px;
      }
    }
  }
}

.description {
  font-size: 14px;
  color: $re-gray-dark;
  margin: 16px 0 0 20px;
  @include respond($mob) {
    font-size: 11px;
    margin: 8px 0 0 60%;
    width: 40%;
  }

  br {
    @include respond($mob) {
      display: none;
    }
  }
}

.options {
  position: absolute;
  z-index: -1;
  bottom: 22px;
  left: 39px;
  display: flex;
  flex-direction: column;
  width: 40%;
  font-size: 16px;
  @include respond($mob) {
    bottom: -74px;
    left: 0;
    width: 54%;
  }

  .option {
    display: flex;

    .iconWrap {
      display: flex;
      align-items: center;
      width: 40px;
    }

    .icon {
      flex-shrink: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 32px 0 0 32px;
      padding: 16px 32px 16px 8px;
      @include respond($mob) {
        width: 32px;
        height: 32px;
        padding: 8px 8px;
      }

      &-red {
        background: $re-red;
      }
    }

    .text {
      display: flex;
      height: 72px;
      padding: 24px 16px;
      align-items: center;
      text-align: left;
      border-radius: 0px 8px 8px 0px;
      background: $re-gray-light;
      @include respond($mob) {
        height: 96px;
        padding: 16px;
      }
    }
  }
}

.productSelector {
  flex-shrink: 0;
  z-index: 1;
  min-height: 462px;

  @media screen and (max-width: 1200px) {
    min-height: 362px;
  }
}
