@import '@scss/service';

.inputWidget {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 58px;

  .input {
    @extend %input;

    border-radius: 10px;
    padding: 0.75em 1.13em;

    &:focus {
      color: $black;

      ~ .label {
        top: 10%;
        font-size: 0.75em;
        color: $gray;
      }
    }
  }

  .currency {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    margin-right: 0.5em;
  }

  .label {
    color: $gray;
    top: 35%;
    left: 0;
    position: absolute;
    transition: all 0.2s;
    pointer-events: none;
    font-size: 1em;
    margin-left: 1.67em;
    font-size: 1em;

    &.notEmpty {
      top: 10%;
      font-size: 0.75em;
      color: $gray;

      @include respond(xs) {
        margin-left: 1.67em;
      }
    }
  }

  &.soft {
    &.placeholder {
      padding-top: 0.85em;
    }

    .input {
      border-radius: 0;
      border: none;
      border-bottom: 2px solid $gray-light;
      padding: 0.75em 0;

      &:focus {
        color: $gray-dark;

        ~ .label {
          top: 0;
          color: $blue-light;
        }

        ~ .hr {
          transform: scaleX(1);
        }
      }
    }

    .label {
      color: $gray-dark;
      top: calc(50% - 0.2em);
      margin-left: 0;

      &.notEmpty {
        top: 0;
        color: $blue-light;
      }
    }

    .hr {
      position: absolute;
      bottom: 0;
      transition: transform 0.2s;
      transform: scaleX(0);
      width: 100%;
      border: none;
      border-bottom: 2px solid $blue-light;
      display: block;
    }

    .error {
      color: $red;
    }
  }

  &_error {
    color: $red !important;
  }
}
