@import '@scss/service';

.loan {
  &Data {
    background-color: $white;
  }

  &Info {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    padding: 8px;
    border-radius: 8px;
    color: $re-gray-dark;
    background-color: $re-gray-light;

    &Item {
      display: flex;
      justify-content: space-between;
      padding: 4px 0;
      &:not(:last-child) {
        border-bottom: 1px dashed $re-gray-dark;
      }
    }

    &Value {
      color: $re-blue;
      font-weight: 700;
    }
  }
}
