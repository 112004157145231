@import '@scss/service';

.select {
  font-size: inherit;
  border-radius: 8px;
  border: 1px solid $form-border;
  background: #F6F7FB;
  height: auto;
  padding: 16px 0;

  .short {
    [class*="value"] {
      margin-top: 0;
    }
  }

  &Control {
    height: 100%;
    position: relative;

    [class*="value"] {
      margin-top: 10px;
    }

  }

  label {
    padding: 0 16px;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #707070;
    position: absolute;
  }

  .option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    cursor: pointer;
    min-height: 48px;

    .optionIcon {
      width: 24px;
      height: 24px;
      margin-right: 16px;
      svg {
        fill: #484951;
      }
    }

    .optionBox {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .optionTextContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .optionText {
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
    }

    .optionSubtext {
      font-size: 10px;
      font-weight: 400;
      line-height: 14px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
    }

    .checkmark {
    }
  }

  .optionSelected {
    background-color: #C6CAD8;
    border-radius: 8px;
  }

  .customButtonContainer {
    padding: 20px 0 0;
    margin-top: 20px;
    border-top: 1px solid #C6CAD8;
    .customButton {
      cursor: pointer;
      padding: 8px 40px;
      border: none;
      background: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 16px;
    }
  }

}