@import '@scss/service';
@import '../client/Client.module.scss';

.pageContainer.pageContainer {
  @include respond($xs) {
    padding: 0 1em;
  }
}

.wrongAccount {
  @extend .clientForm;
  color: $re-gray-dark;

  .button {
    &Submit {
      margin-top: 32px;
    }
  }

  .title {
    margin-top: 48px;
    text-align: center;
    font-size: 20px;
    font-weight: 400;
  }
}

.accounts {
  max-width: 420px; //$md;
  margin-top: 32px;
}

/* .submitButton_old {
  @extend %button;
  background-color: $red;
  width: 100%;
  max-width: 220px;
  margin: 50px auto;
  display: block;
} */
