@import '@scss/service/index';
$mob: $md;

.section {
  width: 100%;
  background-color: $re-gray-light;
}

.content {
  max-width: $lg;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  padding: 40px 16px;
  @include respond($mob) {
    padding: 16px;
  }
}

.image {
  width: 100%;
  text-align: center;
  @include respond($mob) {
    width: 60%;
  }
}

.title {
  font-size: 32px;
  font-weight: 700;
  margin-top: 16px;
  text-align: center;
  max-width: 624px;
  @include respond($mob) {
    font-size: 24px;
    margin-top: 8px;
  }
}

.subTitle {
  color: $re-blue;
  font-size: 16px;
  font-weight: 700;
  margin-top: 24px;
  text-align: center;
  text-transform: uppercase;
  @include respond($mob) {
    font-size: 14px;
    margin-top: 16px;
  }
}

.description {
  margin-top: 16px;
  @include respond($mob) {
    font-size: 14px;
    margin-top: 8px;
  }

  :global .tag_blue {
    color: $re-blue;
  }
}
