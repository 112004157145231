@import '@scss/service';

.tabs {
  width: 100%;
  display: flex;
  font-size: 1em;
  justify-content: center;
  align-items: center;
  max-width: 328px;
  margin: 3em auto 0;
  justify-content: space-between;
  grid-gap: 0.63em;

  @include respond(sm) {
    font-size: 0.9em;
  }
}

.tab {
  content: '';
  border-bottom: 2px solid #DEEDFF;
  flex-grow: 1;
  width: 100%;
  max-width: 70px;
  list-style: none;
  text-align: center;
  border-radius: 10px;
}

.currentTab {
  border-bottom-width: 4px;
  border-bottom-color: #2A70C8;
  color: $blue-light;
  border-radius: 10px;
}
