@import '@scss/service/index';

.loyaltyCode {
  &Main {
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;

    .loyaltyCodeName {
      font-size: 1.1em;
      white-space: nowrap;
      margin-right: 1.1em;
    }

    :global .button-widget {
      font-weight: bold;
      color: #2a70c8;
      padding: 0px 4px;
      margin-left: 0.6em;
      border: 1px solid #2a70c8;
      transition: all 0.2s linear;
      height: 16px;
      font-size: 0.8em;

      &:hover {
        z-index: 1;
        background: url('/images/main-page/welcome/close.svg') no-repeat
          center/cover;
        color: $red;
        // border: 1px solid $red;
      }
    }

    .closeNotify {
      position: absolute;
      width: 6px;
      height: 6px;
      content: '';
      right: 8px;
      border: none;
      background: url('/images/main-page/welcome/close.svg') no-repeat
        center/cover;

      &:hover {
        cursor: pointer;
      }
    }
  }

  &Notify {
    text-align: center;
    display: flex;
    position: absolute;
    top: 35px;
    background-color: #4caf50;
    color: #ffffff;
    font-size: 1em;
    padding: 0.5em 2.2em;
    border-radius: 4px;
    right: 0;
    box-shadow: 0px 10px 33px 2px rgb(34 60 80 / 24%);

    &::before {
      position: absolute;
      top: -18px;
      content: '';
      right: 0px;
      border: 11px solid transparent;
      border-bottom: 9px solid #4caf50;
      border-radius: 6px;
    }
  }

  &Error {
    display: block;
    height: 0;
    line-height: 0;
    margin-top: 0;
    font-size: 0;
    transition: all 0.3s linear;

    &Open {
      height: 100%;
      line-height: 1;
      display: flex;
      margin-top: 0.5em;
      font-size: 1em;
      color: $red;
    }
  }
}

.tooltipNotify {
  margin-top: 0.5em;
  br {
    display: none;
  }
}
