@import '@scss/service';

.documentUploader {
  background: #F6F7FB;
  padding: 30px 16px;
  border-radius: 8px;
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;
  margin-bottom: 40px;
  color: $re-gray-middle;

  &.opened {
    border: 1px solid $re-blue;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .title {
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
    text-align: left;
    margin-bottom: 6px;
  }

  .iconClosed,
  .iconOpen {
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 8px solid $re-blue;
    margin-left: 20px;
  }

  .iconClosed {
    transform: rotate(180deg);
  }

  .description {
    font-size: 12px;
    font-weight: 400;
    line-height: 14.4px;
    text-align: left;
  }

  .divider {
    border: none;
    border-top: 1px solid $re-gray-middle;
    margin: 12px 0;
  }

  .documentsWrap {
    display: flex;
    justify-content: space-around;
    margin: 20px 0;

    .file {
      border: 1px solid $re-gray;
    }
  }

  .uploadSection {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 16px 0 0;

    label {
      margin: 0;
    }
  }

  .uploadBox {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45px;
    height: 45px;
    border: 2px dashed $re-gray-dark;
    cursor: pointer;
    position: relative;
  }

  .fileInput {
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
  }

  .uploadText {
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
    text-align: left;
  }
}
